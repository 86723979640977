import './main.css';
import { useNavigate, useParams } from 'react-router-dom';
import AppInfoLayout from '../components/AppInfoLayout';
import SubscriptionInfoLayout from '../components/SubscriptionInfoLayout';
import { getFamilyBySku } from '../utils/productSKU';

function Main() {

  const navigate = useNavigate();

  const { sku } = useParams();
  


  if (getFamilyBySku(sku) === "HSM") {
    return (
       <AppInfoLayout navigate={navigate} sku={sku} />
    );
  } else
  {
    return (
      <SubscriptionInfoLayout navigate={navigate} sku={sku} />
   );
  }
}

export default Main;
