import { useEffect } from 'react';
import { useLoading } from '../context/LoadingContext';
import encryptStorage from '../utils/storage';

const LoadingWrapper = ({ children }) => {
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/api/users/subscriptions`,
        requestOptions
      )
        .then((res) => {
          if (res.status < 205) {
            //   setDown(false);
            return res.json();
          }
          // setDown(true);
          throw new Error('Something went wrong');
        })
        .then((json) => {
          encryptStorage.setItem('allsubscriptions', json);
          encryptStorage.setItem('wpID', json[0].customerWordpressId);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [setIsLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return children;
};

export default LoadingWrapper;
