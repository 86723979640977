import React, { useEffect, useState } from 'react';
export default function FakeIndicator() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const duration = 30000;

    const interval = setInterval(() => {
      const now = Date.now();
      const elapsed = now - startTime;

      if (elapsed >= duration) {
        clearInterval(interval);
        setProgress(100);
        return;
      }

      const newProgress = Math.min((elapsed / duration) * 100, 100);
      setProgress(newProgress);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const barStyle = {
    width: `${progress}%`,
    height: '20px',
    backgroundColor: '#e8662e',
  };
  return (
    <div style={{ backgroundColor: 'white', width: '100%' }}>
      <div style={barStyle}></div>
    </div>
  );
}
