import React from 'react';

const HubSpotLink = ({ id }) => {
  return (
    <a href={`https://app.hubspot.com/settings/${id}/account-defaults/general`}
       style={{ 
         fontWeight: 'var(--fw-bold)',
         cursor: 'pointer',
         color: '#E8662E',
       }}
       target="_blank"
       rel="noopener noreferrer"
    >
      {id}
    </a>
  );
};

export default HubSpotLink;
