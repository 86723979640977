export const optionTime = [
    { value: '00:00', label: '00:00 UTC' },
    { value: '01:00', label: '01:00 UTC' },
    { value: '02:00', label: '02:00 UTC' },
    { value: '03:00', label: '03:00 UTC' },
    { value: '04:00', label: '04:00 UTC' },
    { value: '05:00', label: '05:00 UTC' },
    { value: '06:00', label: '06:00 UTC' },
    { value: '07:00', label: '07:00 UTC' },
    { value: '08:00', label: '08:00 UTC' },
    { value: '09:00', label: '09:00 UTC' },
    { value: '10:00', label: '10:00 UTC' },
    { value: '11:00', label: '11:00 UTC' },
    { value: '12:00', label: '12:00 UTC' },
    { value: '13:00', label: '13:00 UTC' },
    { value: '14:00', label: '14:00 UTC' },
    { value: '15:00', label: '15:00 UTC' },
    { value: '16:00', label: '16:00 UTC' },
    { value: '17:00', label: '17:00 UTC' },
    { value: '18:00', label: '18:00 UTC' },
    { value: '19:00', label: '19:00 UTC' },
    { value: '20:00', label: '20:00 UTC' },
    { value: '21:00', label: '21:00 UTC' },
    { value: '22:00', label: '22:00 UTC' },
    { value: '23:00', label: '23:00 UTC' }
];
