import Spinner from '../../utils/spinner';

export const handleClose = (setState, setChangeSyncTime) => {
  setChangeSyncTime((state) => !state);
  setState(false);
};

export const SuccessMessage = ({ onClose, time }) => (
  <>
    <div className="dialogtext">
      <p className="success">
        Sync time changed successfully. After your current sync is completed
        your next sync will start at {time} UTC
      </p>
    </div>
    <button className="dwbutton dialogitem" onClick={onClose}>
      Close
    </button>
  </>
);

export const ErrorMessage = ({ onClose }) => (
  <>
    <div className="dialogtext">
      <p className="error">Failed to change sync time</p>
    </div>
    <button className="dwbutton dialogitem" onClick={onClose}>
      Close
    </button>
  </>
);

export const SyncTimeForm = ({
  onSubmit,
  optionTime,
  isGoogleBQ,
  isBYOD,
  submittingDB,
  changingSyncTime,
  onCancel,
}) => (
  <>
    {!changingSyncTime ? (
      <>
        <div className="dialogtext">Select Sync Start Time</div>
        <form onSubmit={onSubmit} className="dialogform">
          <div className="dialogtext">
            <select name="time" id="time" className="dwinputbox">
              {optionTime.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dialogtext">
            <p>
              Select your new initial sync time. Your next sync will start at
              this time, and further syncs will occur at equal intervals based
              on your subscription.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '1em',
              gap: '0.5em',
            }}
          >
            <button
              type="submit"
              disabled={submittingDB}
              className={`dwbutton dialogitem ${
                isGoogleBQ ? 'btn btn-primary' : ''
              }`}
              style={{
                backgroundColor: submittingDB
                  ? isGoogleBQ
                    ? 'var(--clr-bluefaded)'
                    : 'var(--clr-purplefaded)'
                  : false,
              }}
            >
              {!isBYOD && submittingDB && <Spinner className="pos-center" />}
              SUBMIT
            </button>
            <button
              style={{
                backgroundColor: submittingDB
                  ? isGoogleBQ
                    ? 'var(--clr-greyfaded)'
                    : 'var(--clr-purplefaded)'
                  : false,
              }}
              className={`dwbutton dialogitem ${
                isGoogleBQ ? 'btn btn-secondary' : ''
              }`}
              onClick={onCancel}
            >
              CANCEL
            </button>
          </div>
        </form>
      </>
    ) : (
      <div className="pos-center-dialog">
        <Spinner />
      </div>
    )}
  </>
);