// src/utils/handleResponse.js
export const handleResponse = async (response, dispatch, failureAction) => {
  if (response.status >= 205) {
    const error = new Error('Something went wrong with the request');
    console.error('Error:', error);
    dispatch({ type: failureAction, error });
    return null;
  }
  if (response.status === 204) {
    return [];
  }
  const data = await response.json();
  return data;
};
