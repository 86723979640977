const portalToolkitSKU = 'HSMTLKIT';
const schemaReplicatorSKU = 'HSMSCH';
const hsmSchLiteProductCode = 'HSMSCHTLKTL';
const hsmSchProProductCode = 'HSMSCHTLKTP';
const hsmSchProductCodes = [hsmSchLiteProductCode, hsmSchProProductCode];

const productMap = [
  { sku: 'POWERBI', name: 'HubSpot Power BI Connector', family: 'HSC' },

  { sku: 'TABLEAU', name: 'HubSpot Tableau Connector', family: 'HSC' },

  { sku: 'MSSQL', name: 'HubSpot MS SQL Server Integration', family: 'HSC' },

  { sku: 'DOMO', name: 'HubSpot Domo Connector', family: 'HSC' },

  { sku: 'EXCEL', name: 'HubSpot Excel Connector', family: 'HSC' },

  { sku: 'HSM', name: 'HubSpot to HubSpot Migrator', family: 'HSM' },
  {
    sku: schemaReplicatorSKU,
    name: 'HubSpot Schema Replicator',
    family: 'HSM',
  },
  {
    sku: 'GOOGLEDS',
    name: 'HubSpot Looker Studio Connector',
    family: 'HSC',
  },

  { sku: 'ADS', name: 'HubSpot Azure Data Studio Connector', family: 'HSC' },

  {
    sku: 'DPINE',
    name: 'HubSpot Datapine Connector',
    family: 'HSC',
  },

  {
    sku: 'LOOK',
    name: 'HubSpot Looker Connector',
    family: 'HSC',
  },

  {
    sku: 'THSP',
    name: 'HubSpot ThoughtSpot Connector',
    family: 'HSC',
  },

  {
    sku: 'QLIK',
    name: 'HubSpot Qlik Sense Connector',
    family: 'HSC',
  },
  {
    sku: 'METABASE',
    name: 'HubSpot Metabase Connector',
    family: 'HSC',
  },
  {
    sku: 'AMAZONQUICK',
    name: 'HubSpot Amazon Quicksight Connector',
    family: 'HSC',
  },
  {
    sku: 'HSBYOD',
    name: 'Database Sync for HubSpot',
    family: 'HSC',
  },
  {
    sku: 'HIGHTOUCH',
    name: 'Datawarehouse.io for Hightouch',
    family: 'HSC',
  },
  {
    sku: portalToolkitSKU,
    name: 'HubSpot Portal Toolkit',
    family: 'HSM',
  },
];

const getFamilyBySku = (sku) => {
  const product = productMap.find((product) => product.sku === sku);
  return product ? product.family : null;
};

module.exports = {
  productMap,
  getFamilyBySku,
  portalToolkitSKU,
  schemaReplicatorSKU,
  hsmSchLiteProductCode,
  hsmSchProProductCode,
  hsmSchProductCodes,
};
