// store/reducers/subscriptionReducer.js

const initialState = {
  subscriptions: [],
  loading: false,
  error: null,
  hasInitialized: false 
};

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTIONS_START':
    case 'FETCH_SUBSCRIPTIONS_BY_CODES_START':
      return { ...state, loading: true, error: null, hasInitialized: true };

    case 'FETCH_SUBSCRIPTIONS_SUCCESS':
    case 'FETCH_SUBSCRIPTIONS_BY_CODES_SUCCESS':
      const newSubscriptions = action.payload;

      const mergedSubscriptions = newSubscriptions.reduce((acc, newSub) => {
        const existingIndex = acc.findIndex(sub => sub.id === newSub.id);
        if (existingIndex !== -1) {
          acc[existingIndex] = { ...acc[existingIndex], ...newSub };
        } else {
          acc.push(newSub);
        }
        return acc;
      }, [...state.subscriptions]);

      return {
        ...state,
        loading: false,
        subscriptions: mergedSubscriptions,
        hasInitialized: true
      };

    case 'FETCH_SUBSCRIPTIONS_FAILURE':
    case 'FETCH_SUBSCRIPTIONS_BY_CODES_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
        hasInitialized: true
      };
    case 'UPDATE_SUBSCRIPTION_USER_INFO': {
      const updatedSubscriptions = state.subscriptions.map(subscription => {
        if (subscription.id === action.payload.id) {
          return {
            ...subscription,
            email: action.payload.email || subscription.email,
            firstName: action.payload.firstName || subscription.firstName,
            lastName: action.payload.lastName || subscription.lastName,
            company: action.payload.company || subscription.company
          };
        }
        return subscription;
      });
    
      return { ...state, subscriptions: updatedSubscriptions };
    }
    case 'UPDATE_SUBSCRIPTION_DATABASE_INFO': {
      const updatedSubscriptions = state.subscriptions.map(subscription => {
        if (subscription.id === action.payload.id) {
          return {
            ...subscription,
            dbName: action.payload.dbName || subscription.dbName,
          };
        }
        return subscription;
      });
    
      return { ...state, subscriptions: updatedSubscriptions };
    }
    case 'UPDATE_SUBSCRIPTION':
      return {
        ...state,
        subscriptions: state.subscriptions.map(subscription => 
          subscription.id === action.payload.id ? { ...subscription, ...action.payload } : subscription
        )
      };
    default:
      return state;
  }
};
