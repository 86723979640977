// store/reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import { appReducer } from './appReducer';
import { subscriptionReducer } from './subscriptionReducer';
import { skuReducer } from './skuReducer';

const rootReducer = combineReducers({
  app: appReducer,
  subscription: subscriptionReducer,
  sku: skuReducer,
});

export default rootReducer;
