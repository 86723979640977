// store/appStore.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default appStore;
