import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productMap } from '../utils/productSKU';
import FakeIndicator from './FakeIndicator';

function Select() {
  const [selected, setSelected] = useState('');
  const [loading] = useState(false);
  const [down] = useState(false);
  let navigate = useNavigate();
  // const dispatch = useDispatch();

  const handleChange = (e) => {
    const newSku = e.target.value;
    setSelected(newSku);
    navigate(`/account/my-app-information-item/${newSku}`);
  };
  
  if (loading) {
    return (
      <div className="app-container">
        <div style={{ textAlign: 'center' }}>Loading</div>
        <FakeIndicator />
      </div>
    );
  }
  return (
    <div className="app-container">
      <div className="mainheading">
        <div className="headingtitle">
          Select from the following apps. Not all apps can be viewed via this
          panel at this time.
        </div>
        {down ? (
          <p style={{ color: '#e8662e' }}>Service is unavailable.</p>
        ) : (
        <select
          style={{
            background: 'white',
            borderRadius: '5px',
            height: '22px',
            border: '2px solid #d9d9d9',
          }}
          value={selected}
          onChange={handleChange}
        >
          <option value=""></option>
          {productMap.map((item) => (
            <option value={item.sku} key={item.sku}>
              {item.name}
            </option>
          ))}
        </select>
        )}
      </div>
    </div>
  );
}

export default Select;
