import React from 'react';

export function Spinner({ label }) {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const loaderStyle = {
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #E9642F',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 2s linear infinite'
  };

  const labelStyle = {
    marginTop: '10px',
    color: '#333',
    fontSize: '1em'
  };

  return (
    <div className="app-container" style={containerStyle}>
      <div className="loader" style={loaderStyle}></div>
      {label && <div style={labelStyle}>{label}</div>}
    </div>
  );
}

export default Spinner;
