import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { appStore } from './store';
import './index.css';
import Main from './components/Main';
import Detail from './components/Detail';
import Select from './components/Select';
import AppDetails from './components/AppDetails';
import { LoadingProvider } from './context/LoadingContext';
import LoadingWrapper from './components/LoadingWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={appStore}>
    <LoadingProvider>
      <BrowserRouter>
        <LoadingWrapper>
          <Routes>
            <Route
              path="/account/my-app-information-item/"
              element={<Select />}
            />
            <Route
              path="/account/my-app-information-item/:sku"
              element={<Main />}
            />
            <Route
              path="/account/my-app-information-item/subscription/:id"
              element={<Detail />}
            />
            <Route
              path="/account/my-app-information-item/app/:id"
              element={<AppDetails />}
            />
          </Routes>
        </LoadingWrapper>
      </BrowserRouter>
    </LoadingProvider>
  </Provider>
);
