// store/reducers/skuReducer.js
const initialState = {
  fetchedSKUs: []
};

export const skuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_FETCHED_SKU':
      if (!state.fetchedSKUs.includes(action.payload)) {
        return {
          ...state,
          fetchedSKUs: [...state.fetchedSKUs, action.payload]
        };
      }
      return state;
    default:
      return state;
  }
};

