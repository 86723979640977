import React from 'react';
import { Link } from 'react-router-dom';
import './buttonmailto.css';

const ButtonMailto = ({ weight, size }) => {
  return (
    <Link
      className={`contactUs ${weight === 'bold' ? 'bold' : 'regular'}`}
      style={{ fontSize: `${size}px` }}
      to="#"
      onClick={(e) => {
        window.location.href =
          'https://support.datawarehouse.io/hc/en-us/requests/new';
        e.preventDefault();
      }}
    >
      Contact us
    </Link>
  );
};

export default ButtonMailto;
