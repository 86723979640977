import React from 'react';
import './detail.css';

const MessageModal = ({
  passwordRef = null,
  isOpen,
  setIsOpen,
  setIsClose,
  title,
  message = [],
}) => {
  return (
    <>
      {isOpen && (
        <div
          ref={passwordRef}
          className="date-dialog"
          style={{ gap: message.length === 0 && '0em' }}
          onClick={(e) => e.stopPropagation()}
        >
          <h3>{title}</h3>
          <div className="dialog-table">
            {message.length > 0 &&
              message.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p>{item.value}</p>
                  </React.Fragment>
                );
              })}
          </div>
          <div className="dialogitem" onClick={() => setIsClose()}>
            Ok
          </div>
        </div>
      )}
    </>
  );
};

export default MessageModal;
