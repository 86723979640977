import { ReactComponent as Green } from '../assets/green.svg';
import { ReactComponent as Yellow } from '../assets/yellow.svg';
import { ReactComponent as Grey } from '../assets/grey.svg';
import { ReactComponent as Orange } from '../assets/orange.svg';
import { ReactComponent as Red } from '../assets/red.svg';

function Status(props) {
  const { status } = props;
  switch (status) {
    case 'active':
      return (
        <span>
          Active <Green style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'inactive':
      return (
        <span>
          Inactive <Grey style={{ marginLeft: '5px' }} />
        </span>
      );
      case 'pending':
          return (
        <span>
          Pending <Orange style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Not Started':
          return (
        <span>
          {status} <Yellow style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Running':
      return (
        <span>
          {status} <Green style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Completed':
      return (
        <span>
          Completed <Green style={{ marginLeft: '5px' }} />
        </span>
      );

/// <item><term>Planned</term><description>Migration is authorized with HubSpot but not yet started; </description></item>
		/// <item><term>Started</term><description>Migration had been requested to start; </description></item>
		/// <item><term>Running</term><description>Migration is currently running; </description></item>
		/// <item><term>Paused</term><description>Migration is paused and can be resumed; </description></item>
		/// <item><term>Stopped</term><description>Migration was stopped; </description></item>
		/// <item><term>Completed</term><description>Migration had successfully completed; </description></item>
		/// <item><term>Failed</term><description>Migration had failed; </description></item> */}

    case 'Planned':
      return (
        <span>
          {status} <Yellow style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Started':
      return (
        <span>
          {status} <Green style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Paused':
      return (
        <span>
          {status} <Orange style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Stopped':
      return (
        <span>
          {status} <Red style={{ marginLeft: '5px' }} />
        </span>
      );
    case 'Failed':
      return (
        <span>
          {status} <Red style={{ marginLeft: '5px' }} />
        </span>
      );
      


    default:
      return <span />;
  }
}
export default Status;
